<template>
  <div class="root" id="root">
    <nav-menu :name="'Joyce'"></nav-menu>

    <div class="section">
      <div class="picture-container">
        <div class="text">
          <div id="text_container">
            <!-- <span>Joyce Holtz.</span>
            <br /> -->
            <!-- <div class="background"></div> -->
            <!-- <span class="introduction">Hello, my name is Joyce Holtz. I am a Software-Developer based in Germany.</span> -->
            <span>Hello, I'm Joyce!</span>
            <br />
            <span class="introduction">I'm a Software-Developer based in Germany!</span>
          </div>
        </div>
        <div class="img">
          <!-- <img src="./assets/coding.jpg" alt="coding"> -->
        </div>
      </div>
    </div>
  
    <div class="section padding" id="about">
      <div class="text-center heading">
        <h1>Some word's about me</h1>
        <p>
          I am Joyce, an upcoming Software-Developer based in Hanover, Germany. I'm working with
          passion on my programming-skills and I already have some Open-Source-Projects as reference.
        </p>
      </div>
      <div class="description">
        <div class="col">
          <p>
            <span class="capitalize">I</span> 
            completed my apprenticeship as a software developer at NOLIS GmbH in Hanover, Germany in the summer of 2023.
            During my apprenticeship, I also had the honor to work in a foreign software company under the Erasmus(+)-Project 
            and could also convince there through my targeted intention and existing knowledge.

          </p>
          <p class="mt-1">
            Apart from that I also work on private projects or help friends and acquaintances with their projects. 
            I am not satisfied with only 90%, but I always want to get the best out of each project.
          </p>
          <!-- <p class="mt-1">
            Below you can have a closer look at my CV, but also which projects are already published.
          </p> -->
          <!-- <a href="/assets/coding.jpg" download class="btn">Download CV</a> -->
        </div>
        <div class="col">
          <div class="progress">
            <div class="lead">PHP</div>
            <div class="progress-bar" style="width: 85%">
              <span>85%</span>
            </div>
          </div>
          <div class="progress">
            <div class="lead">Javascript</div>
            <div class="progress-bar" style="width: 70%">
              <span>70%</span>
            </div>
          </div>
          <div class="progress">
            <div class="lead">Web-Design</div>
            <div class="progress-bar" style="width: 75%">
              <span>75%</span>
            </div>
          </div>
          <div class="progress">
            <div class="lead">Communication</div>
            <div class="progress-bar" style="width: 90%">
              <span>90%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavMenu from './components/Nav.vue';

export default {
  name: 'App',
  components: {
    NavMenu,
  },
  data() {
    
  },
  mounted() {
    let el = document.getElementById('text_container');
    setTimeout(function() {
      console.log("adsdasd");
      el.classList.add("show");
    }, 10)
  }
}
</script>

<style>
/*
#02394A
#C5EFCB
#7180AC
#E49273
#6D1A36
*/
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');:root { */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
  --primary: #ff4400;
  --primary-light: #ff9f7c;
  --secondary: #ff4400;
  --black: #1d1d1d;
  --grey: #4e4e4e
}
* {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
a {
  color: var(--black);
  text-decoration: none;
}
div.root {
  min-height: 100vh;
  /* min-width: calc(100vw - 2 * 12rem); */
  min-width: 100vw;
  /* background-color: #02394A; */
  /* padding: 0.5rem 12rem; */
  position: relative;
}
div.section {
  max-height: 100vh;
  max-width: 100vw;
}
div.section#about div.heading {
  width: 60rem;
  margin: 0 auto
}
div.section#about div.heading p {
  margin: 1.65rem 0 0;
  color: var(--grey);
}
div.section#about div.description {
  height: auto;
  margin: 4rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--grey);
}
div.section#about div.description div.col {
  height: auto;
  /* border: 1px solid var(--black); */
  width: 45%;
}
div.section#about div.description div.col div.progress {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 13px;
  margin-bottom: 55px;
  overflow: visible;
  position: relative;
}
div.section#about div.description div.col div.progress div.lead {
  color: #222;
  font-size: 14px;
  left: 0;
  position: absolute;
  text-transform: capitalize;
  top: -35px;
  width: 100%;
  z-index: 99;
}
div.section#about div.description div.col div.progress div.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  -webkit-transition: width .6s ease;
  background: #303030 none repeat scroll 0 0;
  position: relative;
  height: 100%;
  width: 0;
  transition: width .6s ease;
}
div.section#about div.description div.col div.progress div.progress-bar > span::before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #000;
  bottom: -8px;
  content: "";
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
div.section#about div.description div.col div.progress div.progress-bar > span {
  color: #303030;
  font-size: 14px;
  height: 24px;
  line-height: 25px;
  position: absolute;
  right: -12px;
  top: -38px;
  width: auto;
}
div.section#about div.description div.col a.btn {
  border: 1px solid var(--primary);
  color: var(--primary);
  text-transform: uppercase;
  padding: 0.6rem;
  font-size: 0.9rem;
  margin: 1rem 0 0;
  width: fit-content;
  display: block;
  transition: background 0.2s ease, color 0.2s ease;
}
div.section#about div.description div.col a.btn:hover {
  background-color: var(--primary);
  color: white;
  /* border: 1px solid var(--primary); */
}
h1 {
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
}


/* HELPER */
.text-center {
  text-align: center;
}
.padding {
  padding: 6rem;
}
.mt-1 {
  margin: 1rem 0 0 0;
}
.capitalize {
  font-size: 3rem;
  color: var(--black);
  float: left;
  padding-right: 10px;
}




div.picture-container {
  /* background: grey; */
  height: calc(100vh - 4.4rem);
  /* max-height: calc(100vh - 4.4rem);
  min-height: calc(100vh - 4.4rem); */
  overflow: hidden;
  position: relative;
}
div.picture-container div.img {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url('./assets/coding.jpg');
  height: 100%;
}
div.picture-container div.text {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
div.picture-container div.text div {
  opacity: 0;
  top: 25%;
  transition: opacity 3s, top 3s;
}
div.picture-container div.text div.show {
  opacity: 1;
  color: white;
  font-size: 4rem;
  font-weight: bold;
  margin: 0 auto;
  display: block;
  position: relative;
  width: fit-content;
  top: 50%;
  transform: translateY(-50%);
  width: 50vw;
  text-align: center;
}
div.picture-container div.text div span.introduction {
  font-size: 1.6rem;
  line-height: 2rem;
  display: block;
  z-index: 3;
  /* background: var(--primary); */
}
</style>
