<template>
  <div class="nav">
    <span class="logo"><a href="#">{{ name }}<span class="dot">.</span></a></span>
    <div class="list-items">
      <a class="btn btn-underlined" v-for="item, index in list_items" :key="index" :href="'#'+index">
        {{item}}
      </a>
    </div>
    <div class="chat">
      <a class="btn btn-outlined" href="mailto: joycejons@gmail.com">
        Let's Chat!
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  props: ['name'],
  data() {
    return {
      list_items: {
        "about": "About & Skills", 
        // "skills": "Skills",
        // "contact": "Contact"
      },
    }
  }
}
</script>

<style>
div.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 1.2rem;
  padding: .85rem;
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
  border-bottom: 1px solid grey;
}

div.nav span.logo {
  color: var(--black);
  font-weight: 900;
  font-size: 2rem;
}
div.nav span.logo span.dot {
  color: var(--black);
  font-weight: 900;
  font-size: 2rem;
  color:var(--primary)
}

div.nav a.btn {
  color: var(--black);
  text-decoration: none;
  /* border: 1px solid var(--primary); */
  /* min-width: 5rem;
  min-height: 2.5rem; */
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  transition: background-color .2s ease, color .2s ease, border-bottom .2s ease;
}

div.nav a.btn:hover {
  background-color: var(--primary);
  color: white;
}

div.nav a.btn.btn-outlined {
  border: 1px solid  var(--primary);
  color: var(--primary);
}

div.nav a.btn.btn-outlined:hover {
  background-color: var(--primary);
  color: white;
}

div.nav a.btn.btn-underlined {
  border-bottom: 3px solid transparent;
  border-radius: 0;
}

div.nav a.btn.btn-underlined:hover {
  background-color: transparent;
  color: var(--black);
  border-bottom: 3px solid  var(--primary);
}

div.nav div.list-items {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
</style>